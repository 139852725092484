import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSwipeable } from 'react-swipeable';
import { motion, AnimatePresence } from 'framer-motion';
import './BookReader.css'; // Ensure you have relevant styles
import books from '../data/bookinfo'; // Ensure the path is correct
import { BackspaceIcon, PlayIcon, SunIcon, MoonIcon } from '@heroicons/react/solid'; // Using heroicons for icons

const swipeVariants = {
  initial: (direction) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0,
    position: 'absolute',
    top: '0px',
    left: 0,
    width: '100%',
  }),
  animate: {
    x: 0,
    opacity: 1,
    position: 'static',
  },
  exit: (direction) => ({
    x: direction < 0 ? 300 : -300,
    opacity: 0,
    position: 'absolute',
    top: '0px',
    left: 0,
    width: '100%',
  }),
};

const BookReader = () => {
  const { bookId } = useParams(); // Assuming route like /reader/:bookId
  const navigate = useNavigate();
  const [book, setBook] = useState(null);
  const [content, setContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [direction, setDirection] = useState(0); // For animation direction
  const [darkMode, setDarkMode] = useState(false); // Dark mode state
  const[totalPages, setTotalPages] = useState(0);

  // const skipTime = 10; // seconds

  // Load persistent dark mode preference from localStorage
  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    if (storedDarkMode) {
      setDarkMode(storedDarkMode === 'true');
      if (storedDarkMode === 'true') {
        document.documentElement.classList.add('dark');
      }
    }
  }, []);

  // Save dark mode preference to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    // Find the book based on bookId
    const selectedBook = books.find((b) => b.id === parseInt(bookId, 10));
    if (selectedBook) {
      setBook(selectedBook);
      // Check if contentUrl is local or remote
      if (selectedBook.contentUrl.startsWith('/')) {
        // Local file
        fetch(selectedBook.contentUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.text();
          })
          .then((data) => {
            paginateContent(data);
            setLoading(false);
          })
          .catch((err) => {
            console.error('Error fetching local content:', err);
            setError('Failed to load book content.');
            setLoading(false);
          });
      } else {
        // Remote file
        axios
          .get(selectedBook.contentUrl)
          .then((response) => {
            paginateContent(response.data);
            setLoading(false);
          })
          .catch((err) => {
            console.error('Error fetching remote content:', err);
            setError('Failed to load book content.');
            setLoading(false);
          });
      }
    } else {
      setError('Book not found.');
      setLoading(false);
    }
  }, [bookId]);

  // Function to paginate content into pages
  const paginateContent = (contentText, charsPerPage = 800) => {
    const pagesArray = [];
    const lines = contentText.split('\n');

    let currentPage = '';
    
    for(let line of lines) {
      if(line.startsWith('#')) { // Check if line looks like a chapter header
        if(currentPage.trim()) {
          pagesArray.push(currentPage.trim());
          currentPage = '';
        }
        currentPage += `<h1 class="chapter">${line.replace('#', '').trim()}</h1>`;
      } else {
        if(currentPage.length + line.length > charsPerPage) {
          pagesArray.push(currentPage.trim());
          currentPage = line + '\n';
        } else {
          currentPage += line + '\n';
        }
      }
    }
    if(currentPage.trim()) {
      pagesArray.push(currentPage.trim());
    }
    setContent(pagesArray);
    setTotalPages(pagesArray.length);
  };

  // Toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode);
      return newMode;
    });
  };

  // Handlers for page navigation wrapped in useCallback
  const handleNextPage = useCallback(() => {
    if (currentPage < content.length - 1) {
      setDirection(1);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, content.length]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 0) {
      setDirection(-1);
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [currentPage]);

  // Handlers for swipe gestures using useSwipeable
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextPage(),
    onSwipedRight: () => handlePreviousPage(),
    preventDefaultTouchmoveEvent: false, // Allow vertical swipes if needed
    trackMouse: true, // Allows swipe with mouse (useful for desktop)
    delta: 50, // Minimum distance(px) before a swipe is detected
  });

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        handleNextPage();
      } else if (e.key === 'ArrowLeft') {
        handlePreviousPage();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleNextPage, handlePreviousPage]);

  // Handle the play button click to navigate to BookScreenSelected
  const handlePlayClick = () => {
    navigate(`/BookScreenSelected/${bookId}`);
  };

  return (
    <div className={`flex flex-col h-screen relative z-10 ${darkMode ? 'dark' : ''} bg-gray-100 dark:bg-gray-800`}>
      {/* Inline Header */}
      <header className="flex items-center justify-between p-2 bg-gray-200 dark:bg-gray-900 relative">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="p-2 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 rounded"
          title="Close"
          aria-label="Close"
        >
          <BackspaceIcon className="h-5 w-5 text-gray-800 dark:text-gray-200" />
        </button>

        {/* Book Thumbnail and Title */}
        <div className="flex items-center space-x-4">
          {book && book.thumbnail && (
            <img
              src={book.thumbnail}
              alt={`Thumbnail of ${book.title}`}
              className="w-10 h-21 object-cover"
            />
          )}
          <h1 className="text-base font-semibold text-gray-800 dark:text-gray-100">
            {book ? book.title : 'Book Reader'}
          </h1>
          <div className="text-xs absolute bottom-2 left-0 right-0 text-center text-gray-600 dark:text-gray-300">
            Page {currentPage + 1} of {totalPages}
          </div>
        </div>

        {/* Right-side Buttons */}
        <div className="flex items-center space-x-3">
          {/* Play Audio Button */}
          {book && book.audioUrl && (
            <button
              onClick={handlePlayClick}
              className="p-1 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 rounded"
              title="Play Audio"
              aria-label="Play Audio"
            >
              <PlayIcon className="h-8 w-8 text-gray-800 dark:text-gray-200" />
            </button>
          )}

          {/* Dark Mode Toggle */}
          <button
            onClick={toggleDarkMode}
            className="p-1 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 rounded"
            title="Toggle Dark Mode"
            aria-label="Toggle Dark Mode"
          >
            {darkMode ? (
              <SunIcon className="h-5 w-5 text-yellow-500" />
            ) : (
              <MoonIcon className="h-5 w-5 text-gray-800 dark:text-gray-200" />
            )}
          </button>
        </div>
      </header>

      {/* Content Area with Swipe Handlers */}
      <div {...handlers} className="flex-1 overflow-hidden relative pt-[1px] pb-16">
        <div className="flex flex-col items-center p-4">
          {loading ? (
            <p className="text-lg">Loading...</p>
          ) : error ? (
            <p className="text-lg text-red-500">{error}</p>
          ) : content.length > 0 ? (
            <AnimatePresence custom={direction} initial={false}>
              <motion.div
                key={currentPage}
                className="page-content w-full max-w-3xl bg-white dark:bg-gray-700 rounded-lg shadow-lg p-6"
                custom={direction}
                variants={swipeVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
              >
                <div
                  className="text-lg whitespace-pre-wrap text-gray-800 dark:text-gray-200"
                  dangerouslySetInnerHTML={{ __html: content[currentPage] }}
                ></div>

              </motion.div>
            </AnimatePresence>
          ) : (
            <p className="text-lg">No content available.</p>
          )}
        </div>

        {/* Page Navigation Buttons */}
        <div className="absolute bottom-2 left-0 right-0 flex justify-between px-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 0}
            className="bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 px-4 py-2 rounded-lg"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === content.length - 1}
            className="bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 px-4 py-2 rounded-lg"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookReader;
