// src/components/MailScreen.js

import React, { useState } from 'react';
import Footer from './Footer';

const MailScreen = () => {
  // State to manage the list of messages
  const [messages, setMessages] = useState([
    {
      id: 1,
      sender: 'alice@example.com',
      subject: 'Meeting Reminder',
      body: 'Don\'t forget about the meeting tomorrow at 10 AM.',
      date: '2023-10-01',
    },
    {
      id: 2,
      sender: 'bob@example.com',
      subject: 'Project Update',
      body: 'The project is on track for the deadline.',
      date: '2023-10-02',
    },
  ]);

  // State to manage the compose form inputs
  const [composeForm, setComposeForm] = useState({
    sender: '',
    subject: '',
    body: '',
  });

  // Handler for input changes in the compose form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setComposeForm({
      ...composeForm,
      [name]: value,
    });
  };

  // Handler for submitting the compose form
  const handleComposeSubmit = (e) => {
    e.preventDefault();
    const { sender, subject, body } = composeForm;

    // Simple validation
    if (!sender || !subject || !body) {
      alert('Please fill in all fields.');
      return;
    }

    const newMessage = {
      id: messages.length + 1,
      sender,
      subject,
      body,
      date: new Date().toISOString().split('T')[0],
    };

    setMessages([newMessage, ...messages]);

    // Reset the compose form
    setComposeForm({
      sender: '',
      subject: '',
      body: '',
    });
  };

  return (
    <div className="flex flex-col h-screen relative z-10 bg-gray-100 dark:bg-gray-800">
      {/* Header */}
      <header className="flex items-center justify-between p-4 bg-white dark:bg-gray-900 shadow">
        <h1 className="text-xl font-semibold text-gray-800 dark:text-gray-200">Mail</h1>
        {/* Compose Button */}
        <button
          type="button"
          className="px-4 py-2 bg-pink-500 text-white rounded hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-400"
          onClick={() => {
            // Focus the compose form
            document.getElementById('compose-form').scrollIntoView({ behavior: 'smooth' });
          }}
        >
          Compose
        </button>
      </header>

      {/* Main Content */}
      <main className="flex-1 overflow-y-auto p-4">
        {/* Messages List */}
        <section aria-labelledby="messages-heading">
          <h2 id="messages-heading" className="sr-only">Messages</h2>
          <ul className="space-y-4">
            {messages.map((message) => (
              <li key={message.id} className="p-4 bg-white dark:bg-gray-700 rounded shadow hover:bg-gray-50 dark:hover:bg-gray-600 transition">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium text-gray-800 dark:text-gray-200">{message.subject}</h3>
                  <span className="text-sm text-gray-500 dark:text-gray-400">{message.date}</span>
                </div>
                <p className="text-gray-600 dark:text-gray-300 mb-2">From: <span className="font-semibold">{message.sender}</span></p>
                <p className="text-gray-700 dark:text-gray-200">{message.body}</p>
              </li>
            ))}
          </ul>
        </section>

        {/* Compose Form */}
        <section id="compose-form" aria-labelledby="compose-heading" className="mt-8">
          <h2 id="compose-heading" className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Compose New Message</h2>
          <form onSubmit={handleComposeSubmit} className="space-y-4">
            {/* Sender Email */}
            <div>
              <label htmlFor="sender" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                From
              </label>
              <input
                type="email"
                id="sender"
                name="sender"
                value={composeForm.sender}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 dark:bg-gray-600 dark:text-gray-200"
                placeholder="you@example.com"
              />
            </div>

            {/* Subject */}
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={composeForm.subject}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 dark:bg-gray-600 dark:text-gray-200"
                placeholder="Subject of your email"
              />
            </div>

            {/* Body */}
            <div>
              <label htmlFor="body" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Message
              </label>
              <textarea
                id="body"
                name="body"
                value={composeForm.body}
                onChange={handleInputChange}
                required
                rows="5"
                className="mt-1 block w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 dark:bg-gray-600 dark:text-gray-200"
                placeholder="Write your message here..."
              ></textarea>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-pink-500 text-white rounded hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-400"
              >
                Send
              </button>
            </div>
          </form>
        </section>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default MailScreen;
