// src/components/Chat/ChatInput.js

import React, { useState, useRef, useEffect } from 'react';
import { PlayIcon, MicrophoneIcon } from '@heroicons/react/outline';
import useMessages from '../../hooks/useMessages';
import PropTypes from 'prop-types';

const ChatInput = ({ onChatActivity }) => {
  const [inputText, setInputText] = useState('');
  const { sendMessage } = useMessages();
  const inputRef = useRef(null); // Reference to the input element

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputText.trim() !== '') {
      sendMessage(inputText);
      setInputText('');
      onChatActivity(); // Notify MainScreen of chat activity
    }
  };

  // Focus the input field to bring up the device keyboard with microphone option
  const handleMicButtonPress = () => {
    inputRef.current.focus();
    onChatActivity(); // Notify MainScreen of chat activity
  };

  // Notify on every input change (optional: to capture typing activity)
  useEffect(() => {
    if (inputText !== '') {
      onChatActivity();
    }
  }, [inputText, onChatActivity]);

  return (
    <div className="absolute bottom-16 w-full px-4">
      <div className="flex items-center bg-white dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-60 rounded-full shadow-lg p-2">
        <form onSubmit={handleSendMessage} className="flex items-center w-full">
          <input
            ref={inputRef} // Reference to the input field
            type="text"
            placeholder="Type a message..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className="flex-grow bg-transparent outline-none px-4 text-black-700 dark:text-gray-200"
          />
          <button type="submit" className="p-2">
            <PlayIcon className="h-8 w-8 text-gray-600 dark:text-gray-300" />
          </button>
        </form>
        {/* Microphone button to trigger keyboard microphone option */}
        <button
          type="button"
          onClick={handleMicButtonPress} // Focuses the input field
          className="p-2 ml-2"
          aria-label="Activate microphone"
        >
          <MicrophoneIcon className="h-8 w-8 text-gray-600 dark:text-gray-300" />
        </button>
      </div>
    </div>
  );
};

// Define prop types for better type checking
ChatInput.propTypes = {
  onChatActivity: PropTypes.func.isRequired,
};

export default ChatInput;
