import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SplashScreen from './components/SplashScreen';
import MainScreen from './components/MainScreen';
import BooksScreen from './components/BooksScreen';
import BookReader from './components/BookReader';
import MailScreen from './components/MailScreen';
import SettingsScreen from './components/SettingsScreen';
import BookScreenSelected from './components/BookScreenSelected';
import Footer from './components/Footer/Footer';
import Backgrounds from './components/Backgrounds';
import { MessageProvider } from './contexts/MessageContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { BackgroundProvider } from './contexts/BackgroundContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useLocalStorage from './hooks/useLocalStorage';
import NewUserExperience from './components/NewUserExperience';

function App() {
  const [isSplashVisible, setIsSplashVisible] = useState(true);

  const [userData, setUserData] = useLocalStorage('userData', {
    isNewUser: true,
    preferences: { theme: 'light', notificationsEnabled: true },
    progress: { completedTutorial: false },
  });

  const handleSplashFinish = () => {
    setIsSplashVisible(false);
  };

  const handleCompleteNewUserExperience = () => {
    setUserData((prevData) => ({
      ...prevData,
      isNewUser: false,
    }));
  };

  return (
    <div className="App relative h-screen">
      {isSplashVisible ? (
        <SplashScreen onFinish={handleSplashFinish} />
      ) : (
        <ThemeProvider>
          <BackgroundProvider>
            <Router>
              <Backgrounds />
              <MessageProvider>
                <div className="flex flex-col h-screen">
                  <main className="flex-1 relative">
                    <Routes>
                      {userData.isNewUser ? (
                        <Route
                          path="/"
                          element={
                            <NewUserExperience
                              onComplete={handleCompleteNewUserExperience}
                            />
                          }
                        />
                      ) : (
                        <Route path="/" element={<MainScreen />} />
                      )}
                      <Route path="/books" element={<BooksScreen />} />
                      <Route path="/reader/:bookId" element={<BookReader />} />
                      <Route path="/mail" element={<MailScreen />} />
                      <Route path="/settings" element={<SettingsScreen />} />
                      <Route
                        path="/BookScreenSelected/:bookId"
                        element={<BookScreenSelected />}
                      />
                    </Routes>
                  </main>
                  <Footer />
                </div>
              </MessageProvider>
            </Router>
            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
          </BackgroundProvider>
        </ThemeProvider>
      )}
    </div>
  );
}

export default App;
