const books = [
  {
    id: 1,
    title: 'Whispers of Second Chances',
    thumbnail: '/assets/images/book1.jpg',
    rating: 4.5,
    accessible: false,
    levelRequired: 1,
    energyRequired: 10,
    questCompleted: false,
    timeSpentOnQuest: 2,
    timeRequired: 2,
    contentUrl: '/books/book1.txt',
    audioUrl: '/books/book1Audio.mp3', // Ensure this file exists
    blurb: 'In the quiet corners of the mind and heart, Whispers of a Second Chance explores the power of forgiveness, redemption, and the subtle influences that guide us towards new beginnings. This moving tale follows the journey of a protagonist seeking solace and renewal, revealing how even the softest whispers can lead to life-altering second chances.',
  },
  {
    id: 2,
    title: 'Midnight Confessions',
    thumbnail: '/assets/images/book2.jpg',
    rating: 5.0,
    accessible: true,
    levelRequired: 0,
    energyRequired: 0,
    questCompleted: false,
    timeSpentOnQuest: 0,
    timeRequired: 0,
    contentUrl: '/books/book2.txt',
    audioUrl: '/books/book2Audio.mp3', // Ensure this file exists
    blurb: 'Midnight Confessions delves into the hidden facets of the human soul, unraveling secrets kept in darkness. Set against a backdrop of intrigue and mystery, this novel takes readers through a thrilling narrative where characters confront their inner demons and forge bonds that transcend the nightly confessions made under the cloak of darkness.',
  },
  {
    id: 3,
    title: 'Midnight Confessions',
    thumbnail: '/assets/images/book3.jpg',
    rating: 5.0,
    accessible: true,
    levelRequired: 0,
    energyRequired: 0,
    questCompleted: false,
    timeSpentOnQuest: 0,
    timeRequired: 0,
    contentUrl: '/books/book2.txt',
    audioUrl: '/books/book2Audio.mp3', // Ensure this file exists
    blurb: 'Midnight Confessions delves into the hidden facets of the human soul, unraveling secrets kept in darkness. Set against a backdrop of intrigue and mystery, this novel takes readers through a thrilling narrative where characters confront their inner demons and forge bonds that transcend the nightly confessions made under the cloak of darkness.',
  },
  {
    id: 4,
    title: 'Midnight Confessions',
    thumbnail: '/assets/images/book2.jpg',
    rating: 5.0,
    accessible: true,
    levelRequired: 0,
    energyRequired: 0,
    questCompleted: false,
    timeSpentOnQuest: 0,
    timeRequired: 0,
    contentUrl: '/books/book2.txt',
    audioUrl: '/books/book2Audio.mp3', // Ensure this file exists
    blurb: 'Midnight Confessions delves into the hidden facets of the human soul, unraveling secrets kept in darkness. Set against a backdrop of intrigue and mystery, this novel takes readers through a thrilling narrative where characters confront their inner demons and forge bonds that transcend the nightly confessions made under the cloak of darkness.',
  },
  {
    id: 5,
    title: 'Midnight Confessions',
    thumbnail: '/assets/images/book2.jpg',
    rating: 5.0,
    accessible: true,
    levelRequired: 0,
    energyRequired: 0,
    questCompleted: false,
    timeSpentOnQuest: 0,
    timeRequired: 0,
    contentUrl: '/books/book2.txt',
    audioUrl: '/books/book2Audio.mp3', // Ensure this file exists
    blurb: 'Midnight Confessions delves into the hidden facets of the human soul, unraveling secrets kept in darkness. Set against a backdrop of intrigue and mystery, this novel takes readers through a thrilling narrative where characters confront their inner demons and forge bonds that transcend the nightly confessions made under the cloak of darkness.',
  },
  // Add more book objects as needed
];

export default books;
