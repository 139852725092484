// src/components/Chat/ChatOutput.js

import React, { useEffect, useRef } from 'react';
import useMessages from '../../hooks/useMessages';
import PropTypes from 'prop-types';

const ChatOutput = ({ isChatActive, onClick }) => {
  const { messages, isTyping } = useMessages();
  const messagesEndRef = useRef(null);

  // Auto-scroll to the bottom when messages or typing status change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isTyping]);

  return (
    <div
      onClick={onClick}
      className={`absolute left-1/2 transform -translate-x-1/2 overflow-y-auto bg-white dark:bg-gray-800 bg-opacity-70 dark:bg-opacity-60 rounded-lg shadow-lg p-4 transition-all duration-300 cursor-pointer ${
        isChatActive
          ? 'w-11/12 max-w-md top-10 h-3/4'
          : 'w-11/12 max-w-md top-[250px] h-[calc(50%-220px)]'
      }`}
      // Inline style for height when using calc()
      style={
        !isChatActive
          ? { height: 'calc(50% - 220px)' }
          : {}
      }
    >
      {messages.length === 0 && isTyping ? (
        // Only Typing Indicator
        <div className="flex mb-2 justify-start">
          <div className="px-4 py-2 rounded-lg bg-gray-300 text-gray-800 rounded-bl-none">
            Typing...
          </div>
        </div>
      ) : (
        <>
          {/* Messages */}
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex mb-2 ${
                message.type === 'user' ? 'justify-end' : 'justify-start'
              }`}
            >
              <div
                className={`px-4 py-2 rounded-lg max-w-xs break-words ${
                  message.type === 'user'
                    ? 'bg-green-500 text-white rounded-br-none'
                    : 'bg-gray-300 text-gray-800 rounded-bl-none'
                }`}
              >
                {message.text}
              </div>
            </div>
          ))}
          {/* Typing Indicator */}
          {isTyping && (
            <div className="flex mb-2 justify-start">
              <div className="px-4 py-2 rounded-lg bg-gray-300 text-gray-800 rounded-bl-none">
                Typing...
              </div>
            </div>
          )}
        </>
      )}
      {/* Dummy div for auto-scrolling */}
      <div ref={messagesEndRef} />
    </div>
  );
};

ChatOutput.propTypes = {
  isChatActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ChatOutput;
