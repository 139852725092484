import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const BookThumbnail = ({ book, userLevel, userEnergy, className }) => {
  const navigate = useNavigate();
  const { title, thumbnail, rating, levelRequired, energyRequired, questCompleted } = book;

  const isAccessible =
    questCompleted || (userLevel >= levelRequired && userEnergy >= energyRequired);

  const displayTitle = title.length > 16 ? `${title.substring(0, 16)}...` : title;

  const handleClick = () => {
    if (isAccessible) {
      navigate(`/reader/${book.id}`);
    } else {
      console.log(`Book "${title}" is not accessible yet.`);
    }
  };

  return (
    <div
      className={`relative inline-block mx-2 cursor-pointer ${className}`}
      onClick={handleClick}
      title={title}
    >
      <img
        src={thumbnail}
        alt={title}
        className={`w-32 h-48 rounded-lg shadow-lg ${isAccessible ? '' : 'opacity-50'}`}
      />
      {!isAccessible && (
        <div className="absolute inset-0 bg-gray-800 opacity-75 rounded-lg flex items-center justify-center text-white text-xs font-semibold">
          Not accessible yet
        </div>
      )}
      <div className="absolute bottom-0 w-full text-center bg-black bg-opacity-50 text-white py-1 rounded-b-lg">
        <p className="text-sm font-semibold">{displayTitle}</p>
        <p className="text-xs">Rating: {rating}</p>
      </div>
    </div>
  );
};

BookThumbnail.propTypes = {
  book: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    levelRequired: PropTypes.number.isRequired,
    energyRequired: PropTypes.number.isRequired,
    questCompleted: PropTypes.bool.isRequired,
  }).isRequired,
  userLevel: PropTypes.number.isRequired,
  userEnergy: PropTypes.number.isRequired,
  className: PropTypes.string,
};

BookThumbnail.defaultProps = {
  className: '',
};

export default BookThumbnail;
