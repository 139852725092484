// src/components/ToggleSwitch.js

import React from 'react';
import PropTypes from 'prop-types';

const ToggleSwitch = ({ label, isOn, handleToggle, IconOn, IconOff }) => {
  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        {isOn ? (
          <IconOn className="h-6 w-6 text-gray-700 dark:text-gray-200 mr-2" aria-hidden="true" />
        ) : (
          <IconOff className="h-6 w-6 text-yellow-500 dark:text-yellow-300 mr-2" aria-hidden="true" />
        )}
        <span className="text-gray-700 dark:text-gray-200">{label}</span>
      </div>
      <button
        onClick={handleToggle}
        aria-label={`Toggle ${label}`}
        className={`relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-pink-500 transition-colors duration-200 ${
          isOn ? 'bg-pink-600 dark:bg-pink-500' : 'bg-gray-200 dark:bg-gray-600'
        }`}
      >
        <span
          className={`${
            isOn ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
        />
        <span className="sr-only">Toggle {label}</span>
      </button>
    </div>
  );
};

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  IconOn: PropTypes.elementType.isRequired,    // Ensures it's a valid React component
  IconOff: PropTypes.elementType.isRequired,   // Ensures it's a valid React component
};

export default ToggleSwitch;
