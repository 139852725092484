// src/contexts/BackgroundContext.js

import React, { createContext, useState, useContext } from 'react';
import backgrounds from '../data/backgroundsdata';

const BackgroundContext = createContext();

export const BackgroundProvider = ({ children }) => {
  const [selectedBackground, setSelectedBackground] = useState(backgrounds[0]);

  const changeBackground = (backgroundId) => {
    const newBackground = backgrounds.find((bg) => bg.id === Number(backgroundId));
    if (newBackground) {
      setSelectedBackground(newBackground);
    }
  };

  return (
    <BackgroundContext.Provider value={{ selectedBackground, changeBackground, backgrounds }}>
      {children}
    </BackgroundContext.Provider>
  );
};

export const useBackground = () => {
  const context = useContext(BackgroundContext);
  if (!context) {
    throw new Error('useBackground must be used within a BackgroundProvider');
  }
  return context;
};
