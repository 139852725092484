import React, { useEffect } from 'react';
import './SplashScreen.css';

const SplashScreen = ({ onFinish }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onFinish();
    }, 6000);

    return () => clearTimeout(timer);
  }, [onFinish]);

  const backgroundImageUrl = `${process.env.PUBLIC_URL}/assets/images/splashSprite.png`;
  const heartsImageUrl = `${process.env.PUBLIC_URL}/assets/images/hearts01.svg`;

  return (
    <div
      className="splash-screen h-screen w-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className="petals-container">
        {Array.from({ length: 8 }).map((_, i) => (
          <img
            key={i}
            src={heartsImageUrl}
            alt="petal"
            className={`petal-animation petal-${i}`}
          />
        ))}
      </div>
   {/*<div className="loading-text text-xl text-white animate-bounce bottom:">Loading</div>*/}
      {/* Loading Text */}
      <div
        className="absolute text-xl text-white animate-bounce"
        style={{
          bottom: 'calc(12px + 76px)', // 6px above copyright
        }}
      >
        Loading
      </div>

      <footer className="absolute bottom-4 text-sm text-white opacity-75 text-center">
        <p>© {new Date().getFullYear()} EoE-Media. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default SplashScreen;
