import React from 'react';
import { MessageProvider } from '../contexts/MessageContext';
import Footer from './Footer';
import BookCollection from './BookCollection';
import bookinfo from '../data/bookinfo'; // Import book data

const BooksScreen = () => {
  const userLevel = 1; // Example user level
  const userEnergy = 50; // Example user energy

  return (
    <MessageProvider>
      <div className="h-screen flex flex-col">
        <main className="flex-1 overflow-y-auto">
          {/* Pass bookinfo to BookCollection */}
          <BookCollection
            title="My Collection"
            userLevel={userLevel}
            userEnergy={userEnergy}
            books={bookinfo}
          />
          <BookCollection
            title="Best Sellers"
            userLevel={userLevel}
            userEnergy={userEnergy}
            books={bookinfo.filter((book) => book.rating >= 4.5)}
          />
          <BookCollection
            title="Suggested"
            userLevel={userLevel}
            userEnergy={userEnergy}
            books={bookinfo.filter((book) => book.accessible)}
          />
        </main>
        <Footer />
      </div>
    </MessageProvider>
  );
};

export default BooksScreen;
