// src/contexts/MessageContext.js

import React, { createContext, useState } from 'react';
import initialMessages from '../data/messages';

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([initialMessages.initialServerMessage]);
  const [isTyping, setIsTyping] = useState(false);

  return (
    <MessageContext.Provider
      value={{ messages, setMessages, isTyping, setIsTyping }}
    >
      {children}
    </MessageContext.Provider>
  );
};
