import React, { useState, useEffect } from 'react';
import './NewUserExperience.css';

const NewUserExperience = ({ onComplete }) => {
  const [spriteVisible, setSpriteVisible] = useState(true);
  const [message, setMessage] = useState('');
  const fullMessage =
    'We have some great news to share! Since this is your first time, we can chat one on one about anything for a short period of time. After that, just fill your "Hearts" up whenever you need a friend.';

  useEffect(() => {
    const spriteTimeout = setTimeout(() => {
      setSpriteVisible(false); // Trigger sprite to slide off after 2 seconds
    }, 2000);

    let messageIndex = 0;
    const typingInterval = setTimeout(() => {
      const interval = setInterval(() => {
        setMessage((prev) => prev + fullMessage[messageIndex]);
        messageIndex += 1;

        if (messageIndex === fullMessage.length) {
          clearInterval(interval);
        }
      }, 50); // Typing speed
    }, 2000); // Delay until typing starts after sprite slides out

    return () => {
      clearTimeout(spriteTimeout);
      clearTimeout(typingInterval);
    };
  }, []);

  return (
    <div className="new-user-container fixed top-0 left-0 w-full h-full flex items-center justify-center z-10">
      {/* Background Container */}
      <div
        className="background-container absolute top-4 left-1/2 transform -translate-x-1/2 w-full h-[calc(50%-20px)] max-w-screen-lg rounded-lg p-4 z-0"
        style={{
          background: 'linear-gradient(to bottom, #f6a6b5, #f8f6f7)',
        }}
      >
        <h1 className="absolute top-5 left-1/2 transform -translate-x-1/2 text-3xl font-semibold text-gray-800 dark:text-gray-100 w-full max-w-screen-lg text-center">
          Echoes of EROS!
        </h1>
        <p className="mt-20 mb-6 text-base text-gray-800 dark:text-gray-200">
          {message}
        </p>
        <button
          onClick={onComplete}
          className="absolute left-1/2 transform -translate-x-1/2 bottom-5 bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-400"
        >
          Complete Tutorial
        </button>
      </div>

      {/* Splash Sprite */}
      <div
        className={`splash-sprite absolute top-0 left-0 w-full h-full bg-cover bg-center z-0 transition-transform duration-1000 ${
          spriteVisible ? 'sprite-visible' : 'sprite-hidden'
        }`}
        style={{
          backgroundImage: 'url(/assets/images/splashSprite.png)',
        }}
      />
    </div>
  );
};

export default NewUserExperience;
