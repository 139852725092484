// src/components/Icons.js

import {
    SunIcon,
    MoonIcon,
    MicrophoneIcon,
    StatusOfflineIcon,
    VolumeUpIcon,
    VolumeOffIcon,
    ArrowsExpandIcon,
    ArrowDownIcon,
  } from '@heroicons/react/outline';
  
  // Standardized icon names mapped to actual icon components
  export const Icons = {
    darkMode: {
      on: MoonIcon,    // Represents Dark Mode enabled
      off: SunIcon,    // Represents Dark Mode disabled
    },
    microphone: {
      on: MicrophoneIcon,       // Represents Microphone enabled
      off: StatusOfflineIcon,   // Represents Microphone disabled
    },
    sounds: {
      on: VolumeUpIcon,    // Represents Sounds unmuted
      off: VolumeOffIcon,  // Represents Sounds muted
    },
    fullscreen: {
      on: ArrowsExpandIcon, // Represents entering Fullscreen
      off: ArrowDownIcon,       // Represents exiting Fullscreen
    },
  };
  
  export default Icons;
  