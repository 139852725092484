import React from 'react';
import PropTypes from 'prop-types';
import BookThumbnail from './BookThumbnail';

const BookCollection = ({ title, userLevel, userEnergy, books }) => {
  return (
    <div className="mb-8 px-2">
      <h2 className="text-lg font-bold italic underline text-gray-200 mb-2">
        {title}
      </h2>
      <div className="flex overflow-x-auto space-x-4 snap-x snap-mandatory scrollbar-hide">
        {books.map((book) => (
          <BookThumbnail
            key={book.id}
            book={book}
            userLevel={userLevel}
            userEnergy={userEnergy}
            className="flex-shrink-0 snap-center"
          />
        ))}
      </div>
    </div>
  );
};

BookCollection.propTypes = {
  title: PropTypes.string.isRequired,
  userLevel: PropTypes.number.isRequired,
  userEnergy: PropTypes.number.isRequired,
  books: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default BookCollection;
