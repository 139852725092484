import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import Footer from './Footer';
import Icons from './Icons';
import ToggleSwitch from './ToggleSwitch';
import useLocalStorage from '../hooks/useLocalStorage';
import debounce from 'lodash.debounce';
import { ThemeContext } from '../contexts/ThemeContext';
import { useBackground } from '../contexts/BackgroundContext';
import backgrounds from '../data/backgroundsdata';

const SettingsScreen = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const { changeBackground, selectedBackground } = useBackground();
  const [areSoundsMuted, setAreSoundsMuted] = useLocalStorage('areSoundsMuted', false);
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [userData, setUserData] = useLocalStorage('userData', {
    isNewUser: true,
    preferences: { theme: 'light', notificationsEnabled: true },
    progress: { completedTutorial: false },
  });

  const openFullscreen = useCallback(() => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
    if (window.screen.orientation && window.screen.orientation.lock) {
      window.screen.orientation.lock('portrait').catch((error) => console.log('Orientation lock error:', error));
    }
  }, []);

  const closeFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }, []);

  const debouncedToggleSounds = useMemo(
    () => debounce(() => setAreSoundsMuted((prev) => !prev), 300),
    [setAreSoundsMuted]
  );

  const debouncedToggleFullscreen = useMemo(
    () => debounce(() => {
      if (!isFullscreen) openFullscreen();
      else closeFullscreen();
    }, 300),
    [isFullscreen, openFullscreen, closeFullscreen]
  );

  useEffect(() => {
    return () => {
      debouncedToggleSounds.cancel();
      debouncedToggleFullscreen.cancel();
    };
  }, [debouncedToggleSounds, debouncedToggleFullscreen]);

  const handleToggleDarkMode = () => {
    toggleTheme();
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFull =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;
      setIsFullscreen(!!isFull);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  useEffect(() => {
    document.querySelectorAll('audio, video').forEach((audio) => {
      audio.muted = areSoundsMuted;
    });
  }, [areSoundsMuted]);

  const handleBackgroundChange = (e) => {
    changeBackground(e.target.value);
  };

  return (
    <>
      <div className="flex flex-col h-screen relative z-10 pointer-events-none">
        <header className="flex items-center justify-between p-4 bg-white dark:bg-gray-900 shadow pointer-events-auto">
          <h1 className="text-xl font-semibold text-gray-800 dark:text-gray-200">Settings</h1>
        </header>

        <main className="flex-1 p-6 overflow-y-auto">
          <div className="max-w-md mx-auto bg-white bg-opacity-80 dark:bg-gray-700 dark:bg-opacity-80 rounded-lg shadow p-6 backdrop-filter backdrop-blur-sm pointer-events-auto">
            <h2 className="text-lg font-medium text-gray-800 dark:text-gray-200 mb-4">Preferences</h2>

            <ToggleSwitch
              label="Dark Mode"
              isOn={isDarkMode}
              handleToggle={handleToggleDarkMode}
              IconOn={Icons.darkMode.on}
              IconOff={Icons.darkMode.off}
            />

            <ToggleSwitch
              label="Sounds"
              isOn={!areSoundsMuted}
              handleToggle={debouncedToggleSounds}
              IconOn={Icons.sounds.on}
              IconOff={Icons.sounds.off}
            />

            <ToggleSwitch
              label="Fullscreen"
              isOn={isFullscreen}
              handleToggle={debouncedToggleFullscreen}
              IconOn={Icons.fullscreen.off}
              IconOff={Icons.fullscreen.on}
            />

            <div className="mt-6">
              <label htmlFor="background-select" className="block text-gray-800 dark:text-gray-200 mb-2">
                Select Background
              </label>
              <select
                id="background-select"
                value={selectedBackground.id}
                onChange={handleBackgroundChange}
                className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {backgrounds.map((bg) => (
                  <option key={bg.id} value={bg.id}>
                    {bg.name}{bg.type === 'iframe' ? ' - Premium' : ''}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </main>

        <div className="text-center text-sm text-gray-0 dark:text-gray-200 py-2" style={{ marginBottom: '3.5rem' }}>
          © {new Date().getFullYear()} EoE-Media. All rights reserved.
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SettingsScreen;