import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  PlayIcon,
  PauseIcon,
  XIcon,
  StarIcon as StarSolid,
  VolumeUpIcon,
  VolumeOffIcon,
} from '@heroicons/react/solid';
import { StarIcon as StarOutline } from '@heroicons/react/outline'; // For an outlined star
import books from '../data/bookinfo'; // Import your book data

const MAX_RATING = 5; // Maximum rating stars
const BookScreenSelected = () => {
  const { bookId } = useParams();
  const navigate = useNavigate();
  const [book, setBook] = useState(null);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const skipTime = 10; // skip 10 seconds
  const [userRating, setUserRating] = useState(0); // User rating
  const [isImageZoomed, setIsImageZoomed] = useState(false); // State for image zoom modal
  const [volume, setVolume] = useState(1); // Volume (0 to 1)
  const [isMuted, setIsMuted] = useState(false); // Mute state

  useEffect(() => {
    // Find the selected book by ID
    const selectedBook = books.find((b) => b.id === parseInt(bookId, 10));
    if (selectedBook) {
      setBook(selectedBook);
    } else {
      navigate('/books'); // If book not found, redirect to books list
    }
  }, [bookId, navigate]);

  // Update current time and duration as the audio plays
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    const handleDurationChange = () => setDuration(audio.duration);
    const handleEnded = () => setIsPlaying(false);

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('durationchange', handleDurationChange);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('durationchange', handleDurationChange);
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  // The user must manually start the audio by clicking play to avoid autoplay issues
  useEffect(() => {
    if (book && audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [book, volume]);

  // Play or pause audio
  const togglePlayPause = useCallback(() => {
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      // Attempt to play the audio (triggered by user action)
      audioRef.current.play().catch((error) => {
        // Handle any playback errors
        console.error('Audio playback failed:', error);
      });
      setIsPlaying(true);
    }
  }, [isPlaying]);

  // Rewind 10 seconds
  const handleRewind = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(
        audioRef.current.currentTime - skipTime,
        0
      );
    }
  }, [skipTime]);

  // Forward 10 seconds
  const handleForward = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(
        audioRef.current.currentTime + skipTime,
        audioRef.current.duration
      );
    }
  }, [skipTime]);

  // Restart audio from beginning
  const handleRestart = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((error) => {
        console.error('Audio playback failed:', error);
      });
      setIsPlaying(true);
    }
  }, []);

  // Handle seeking using the range input
  const handleSeek = useCallback((time) => {
    if (audioRef.current) {
      audioRef.current.currentTime = time;
    }
  }, []);

  // Handle volume change
  const handleVolumeChange = useCallback((newVolume) => {
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
      setVolume(newVolume);
      setIsMuted(newVolume === 0);
    }
  }, []);

  // Toggle mute
  const toggleMute = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
      if (!isMuted) {
        setVolume(0);
      } else {
        setVolume(1);
        audioRef.current.volume = 1;
      }
    }
  }, [isMuted]);

  // Format time in mm:ss
  const formatTime = (time) => {
    if (isNaN(time)) return '00:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${
      minutes < 10 ? '0' + minutes : minutes
    }:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  // Toggle the image zoom modal
  const toggleImageZoom = () => {
    setIsImageZoomed((prev) => !prev);
  };

  // Handle user rating
  const handleUserRating = (ratingValue) => {
    setUserRating(ratingValue);
  };

  // Calculate combined rating (average of user rating and book rating, if user rating is given)
  const combinedRating =
    userRating > 0 ? (book?.rating + userRating) / 2 : book?.rating;

  return (
    <div className="flex flex-col h-screen relative z-10 bg-gray-100 dark:bg-gray-800">
      {/* Main Content Area */}
      <div className="flex-1 overflow-hidden relative pt-4 pb-16 flex flex-col items-center">
        {book ? (
          <div className="flex flex-col items-center w-full p-4 space-y-6 max-w-3xl relative">
            {/* Back Button in Upper Left Corner */}
            <button
              onClick={() => navigate(-1)}
              className="absolute top-1 left-4 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200 rounded"
              title="Go Back"
              aria-label="Go Back"
            >
              <XIcon className="h-5 w-5 text-gray-800 dark:text-gray-200" />
            </button>

            {/* Large Cover Image */}
            {book.thumbnail && (
              <div className="relative mt-8">
                <img
                  src={book.thumbnail}
                  alt={`Cover of ${book.title}`}
                  className="rounded-lg shadow-lg max-h-60 cursor-zoom-in object-cover"
                  onClick={toggleImageZoom}
                />
                {isImageZoomed && (
                  <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                    onClick={toggleImageZoom}
                  >
                    <img
                      src={book.thumbnail}
                      alt={`Cover of ${book.title}`}
                      className="max-h-[90%] max-w-[90%] object-contain cursor-zoom-out"
                    />
                  </div>
                )}
              </div>
            )}

            {/* Book Information */}
            <div className="w-full flex flex-col items-start space-y-4 mt-2">
              {/* Title */}
              <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">
                {book.title}
              </h2>

              {/* Rating and User Rating */}
              <div className="flex items-center space-x-3">
                {/* Display combined rating */}
                <div className="flex items-center">
                  {Array.from({ length: 5 }, (_, i) => {
                    const ratingValue = i + 1;
                    return ratingValue <= Math.round(combinedRating) ? (
                      <StarSolid
                        key={ratingValue}
                        className="h-6 w-6 text-yellow-400"
                      />
                    ) : (
                      <StarOutline
                        key={ratingValue}
                        className="h-6 w-6 text-gray-400"
                      />
                    );
                  })}
                </div>
                <span className="text-sm text-gray-600 dark:text-gray-300">
                  {combinedRating.toFixed(1)} / {MAX_RATING}
                </span>
              </div>

              {/* User Rating Input */}
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-800 dark:text-gray-200">
                  Your Rating:
                </span>
                <div className="flex items-center">
                  {Array.from({ length: 5 }, (_, i) => {
                    const ratingValue = i + 1;
                    return (
                      <button
                        key={ratingValue}
                        onClick={() => handleUserRating(ratingValue)}
                        className="focus:outline-none"
                        aria-label={`Rate this book ${ratingValue} stars`}
                      >
                        {ratingValue <= userRating ? (
                          <StarSolid className="h-6 w-6 text-yellow-400" />
                        ) : (
                          <StarOutline className="h-6 w-6 text-gray-400 hover:text-yellow-400" />
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>

              {/* Blurb */}
              {book.blurb && (
                <div className="p-1 text-gray-800 dark:text-gray-200 text-sm leading-relaxed">
                  <strong>Description: </strong>
                  {book.blurb}
                </div>
              )}
            </div>

            {/* Updated Audio Controls Layout */}
            <div className="bg-gray-200 dark:bg-gray-700 p-4 rounded-lg w-full max-w-md flex flex-col items-center space-y-4 mt-4">
              {/* Duration and Time Slider Positioned Above Controls */}
              <div className="flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-300 w-full">
                <span>{formatTime(currentTime)}</span>
                <input
                  type="range"
                  min="0"
                  max={duration}
                  value={currentTime}
                  onChange={(e) => handleSeek(Number(e.target.value))}
                  className="flex-1 h-1 cursor-pointer"
                />
                <span>{formatTime(duration)}</span>
              </div>

              {/* Playback Controls Below Duration */}
              <div className="flex items-center justify-center space-x-4 w-full">
                {/* Restart Button */}
                <button
                  onClick={handleRestart}
                  className="p-1 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 rounded"
                  title="Restart from Beginning"
                  aria-label="Restart"
                >
                  <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-800 dark:text-gray-200" />
                </button>

                {/* Rewind Button */}
                <button
                  onClick={handleRewind}
                  className="p-1 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 rounded"
                  title="Rewind 10 seconds"
                  aria-label="Rewind 10 seconds"
                >
                  <ChevronLeftIcon className="h-7 w-7 text-gray-800 dark:text-gray-200" />
                </button>

                {/* Play/Pause Button */}
                <button
                  onClick={togglePlayPause}
                  className="p-1 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 rounded"
                  title={isPlaying ? 'Pause' : 'Play'}
                  aria-label={isPlaying ? 'Pause' : 'Play'}
                >
                  {isPlaying ? (
                    <PauseIcon className="h-8 w-8 text-gray-800 dark:text-gray-200" />
                  ) : (
                    <PlayIcon className="h-8 w-8 text-gray-800 dark:text-gray-200" />
                  )}
                </button>

                {/* Forward Button */}
                <button
                  onClick={handleForward}
                  className="p-2 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 rounded"
                  title="Forward 10 seconds"
                  aria-label="Forward 10 seconds"
                >
                  <ChevronRightIcon className="h-7 w-7 text-gray-800 dark:text-gray-200" />
                </button>
              </div>

              {/* Volume Controls */}
              <div className="flex items-center space-x-2 w-full justify-center">
                {/* Mute/Unmute Button */}
                <button
                  onClick={toggleMute}
                  className="p-2 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 rounded"
                  title={isMuted ? 'Unmute' : 'Mute'}
                  aria-label={isMuted ? 'Unmute' : 'Mute'}
                >
                  {isMuted ? (
                    <VolumeOffIcon className="h-5 w-5 text-gray-800 dark:text-gray-200" />
                  ) : (
                    <VolumeUpIcon className="h-5 w-5 text-gray-800 dark:text-gray-200" />
                  )}
                </button>

                {/* Volume Slider */}
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={(e) => handleVolumeChange(Number(e.target.value))}
                  className="h-1 flex-1 cursor-pointer"
                  aria-label="Volume"
                />
              </div>
            </div>
          </div>
        ) : (
          <p className="text-lg text-gray-800 dark:text-gray-100">
            Loading book information...
          </p>
        )}
      </div>

      {/* Audio Element (Placed Outside Main Content for Clarity) */}
      {book && book.audioUrl && (
        <audio ref={audioRef} src={book.audioUrl} preload="metadata" />
      )}
    </div>
  );
};

export default BookScreenSelected;
