// src/data/backgrounds.js

const backgrounds = [
      {
      id: 1,
      name: 'Amber',
      type: 'video',
      src: '/assets/video/LS_TK2.0000.mp4', // or you could have a URL here
      poster: '/assets/images/backgroundSprite.png', // Optional: image to show before video plays or when loading
      options: {
        // Additional video options if needed
        loop: true,
        muted: true, // This would be true if you want the video to start muted
        autoPlay: true // If you want the video to start playing automatically
      }
    },
    {
      id: 1,
      name: 'Amber',
      type: 'iframe',
      src: 'http://192.168.1.115/?AutoConnect=true&StartVideoMuted=true',
    },
    {
      id: 3,
      name: 'Charles',
      type: 'iframe',
      src: 'http://192.168.1.115/?AutoConnect=true&StartVideoMuted=true',
    },
    {
      id: 4,
      name: 'Agatha',
      type: 'iframe',
      src: 'http://192.168.1.115/?AutoConnect=true&StartVideoMuted=true',
    },
    {
      id: 5,
      name: 'Soothing Cyan',
      type: 'image',
      src: '/assets/images/backgroundSprite5.jpg',
    },
    {
      id: 6,
      name: 'Blues Away',
      type: 'image',
      src: '/assets/images/backgroundSprite6.jpg',
    },
    {
      id: 7,
      name: 'Tulip Dreams',
      type: 'image',
      src: '/assets/images/backgroundSprite7.jpg',
    },
    {
      id: 8,
      name: 'Sandy Toes',
      type: 'image',
      src: '/assets/images/backgroundSprite8.jpg',
    },
    {
      id: 9,
      name: 'Stately Mention',
      type: 'image',
      src: '/assets/images/backgroundSprite3.jpg',
    },
    {
      id: 10,
      name: 'Sky is the Limit',
      type: 'image',
      src: '/assets/images/backgroundSprite1.jpg',
    },
    {
      id: 11,
      name: 'Modern Chic',
      type: 'image',
      src: '/assets/images/backgroundSprite11.jpg',
    },
    {
      id: 12,
      name: 'Romantic Vibes',
      type: 'image',
      src: '/assets/images/backgroundSprite12.jpg',
    },
  ];
  
  export default backgrounds;
  