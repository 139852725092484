import React, { Suspense, lazy, useRef, useEffect } from 'react';
import { useBackground } from '../contexts/BackgroundContext';

const LazyIframe = lazy(() => import('./LazyIframe'));
const LazyVideo = lazy(() => import('./LazyVideo'));

const Backgrounds = () => {
  const { selectedBackground } = useBackground();
  const videoRef = useRef(null);

  const renderBackground = () => {
    if (selectedBackground.type === 'iframe') {
      return <LazyIframe src={selectedBackground.src} />;
    } else if (selectedBackground.type === 'image') {
      return (
        <img
          src={selectedBackground.src}
          alt="Background"
          className="fixed top-0 left-0 w-full h-full object-cover z-0"
        />
      );
    } else if (selectedBackground.type === 'video') {
      return (
        <LazyVideo
          ref={videoRef}
          src={selectedBackground.src}
          poster={selectedBackground.poster}
          options={selectedBackground.options}
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (videoRef.current && selectedBackground.type === 'video') {
      if (selectedBackground.options.autoPlay) {
        videoRef.current.play();
      }
      if (selectedBackground.options.loop) {
        videoRef.current.loop = true;
      }
      if (selectedBackground.options.muted) {
        videoRef.current.muted = true;
      }
    }
  }, [selectedBackground]);

  return (
    <div className="background-container fixed top-0 left-0 w-full h-full z-0">
      <Suspense
        fallback={<div className="fixed top-0 left-0 w-full h-full z-0 bg-gray-100"></div>}
      >
        {selectedBackground ? renderBackground() : null}
      </Suspense>
    </div>
  );
};

export default Backgrounds;
