// src/hooks/useMessages.js

import { useContext, useEffect, useRef } from 'react';
import { MessageContext } from '../contexts/MessageContext';
import initialMessages from '../data/messages';

const useMessages = () => {
  const context = useContext(MessageContext);

  if (!context) {
    throw new Error('useMessages must be used within a MessageProvider');
  }

  const { messages, setMessages, isTyping, setIsTyping } = context;

  // Use a ref to track if the initial message has been added
  const hasAddedInitialMessage = useRef(false);

  // Initialize with the initial server message after a delay
  useEffect(() => {
    if (hasAddedInitialMessage.current) {
      return; // Skip if already added
    }

    hasAddedInitialMessage.current = true; // Mark as added

    // Simulate server typing
    setIsTyping(true);

    const initialMessageTimeout = setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        initialMessages.initialServerMessage,
      ]);
      setIsTyping(false); // Server stops typing
    }, 500); // 0.5-second delay

    return () => clearTimeout(initialMessageTimeout);
  }, [setIsTyping, setMessages]); // Added dependencies

  // Function to send a message
  const sendMessage = (inputText) => {
    if (inputText.trim() !== '') {
      // Add user's message
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'user', text: inputText },
      ]);

      // Simulate server typing
      setIsTyping(true);

      // Simulate delay before server response
      setTimeout(() => {
        // Determine server response based on user input
        let serverResponse = {
          type: 'server',
          text: `Echo: ${inputText}`,
        };

        // Server sends a response
        setMessages((prevMessages) => [...prevMessages, serverResponse]);
        setIsTyping(false); // Server stops typing
      }, 1000); // 1-second delay
    }
  };

  return {
    messages,
    isTyping,
    sendMessage,
  };
};

export default useMessages;
