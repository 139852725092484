// src/data/messages.js

const initialMessages = {
    initialServerMessage: {
      type: 'server',
      text: "Hey wow you are never going to believe this! I sure do have something to tell you. Let me know if you have time to chat—you will want to hear this story!",
    },
    responses: {
      hello: {
        type: 'server',
        text: "Hello! It's great to hear from you again!",
      },
      // Add more predefined responses here
    },
  };
  
  export default initialMessages;
  