// src/components/Footer/Footer.js

import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  HomeIcon,
  BookOpenIcon,
  MailIcon,
  CogIcon, // Import CogIcon for Settings
} from '@heroicons/react/outline';

const Footer = () => {
  return (
    <div className="fixed bottom-0 w-full bg-white dark:bg-gray-900 shadow-inner z-50">
      <div className="flex justify-around items-center py-2">
        {/* Home Button */}
        <NavLink
          to="/"
          aria-label="Home"
          className={({ isActive }) =>
            `flex flex-col items-center ${
              isActive ? 'text-pink-500' : 'text-gray-600 dark:text-gray-300'
            } hover:text-pink-400 transition-colors`
          }
        >
          <HomeIcon className="h-6 w-6" />
          <span className="text-xs">Home</span>
        </NavLink>

        {/* Books Button */}
        <NavLink
          to="/books"
          aria-label="Books"
          className={({ isActive }) =>
            `flex flex-col items-center ${
              isActive ? 'text-pink-500' : 'text-gray-600 dark:text-gray-300'
            } hover:text-pink-400 transition-colors`
          }
        >
          <BookOpenIcon className="h-6 w-6" />
          <span className="text-xs">Books</span>
        </NavLink>

        {/* Mail Button */}
        <NavLink
          to="/mail"
          aria-label="Mail"
          className={({ isActive }) =>
            `flex flex-col items-center ${
              isActive ? 'text-pink-500' : 'text-gray-600 dark:text-gray-300'
            } hover:text-pink-400 transition-colors`
          }
        >
          <MailIcon className="h-6 w-6" />
          <span className="text-xs">Mail</span>
        </NavLink>

        {/* Settings Button */}
        <NavLink
          to="/settings"
          aria-label="Settings"
          className={({ isActive }) =>
            `flex flex-col items-center ${
              isActive ? 'text-pink-500' : 'text-gray-600 dark:text-gray-300'
            } hover:text-pink-400 transition-colors`
          }
        >
          <CogIcon className="h-6 w-6" />
          <span className="text-xs">Settings</span>
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
