import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MessageProvider } from '../contexts/MessageContext';
import { ChatInput, ChatOutput } from './Chat';
import BookCollection from './BookCollection';
import bookinfo from '../data/bookinfo'; // Import shared book data
import debounce from 'lodash.debounce';

const MainScreen = () => {
  const userLevel = 1; // Example user level
  const userEnergy = 50; // Example user energy

  // State to manage chat activity
  const [isChatActive, setIsChatActive] = useState(false);

  // Ref to store the current timeout ID
  const timeoutIdRef = useRef(null);

  // Ref to store the debounced toggleChat function
  const toggleChatRef = useRef(
    debounce(() => {
      setIsChatActive((prev) => {
        const newState = !prev;
        if (newState) {
          // If activating chat, clear existing timeout and set a new one
          if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
          }
          timeoutIdRef.current = setTimeout(() => {
            setIsChatActive(false);
          }, 20000); // 20 seconds
        } else {
          // If deactivating chat, clear existing timeout
          if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
          }
        }
        return newState;
      });
    }, 300) // Debounce delay of 300ms
  );

  // Function to activate chat and reset timeout
  const activateChat = useCallback(() => {
    setIsChatActive(true);
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      setIsChatActive(false);
    }, 60000); // 60 seconds
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    const currentToggleChat = toggleChatRef.current;

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      currentToggleChat.cancel();
    };
  }, []);

  return (
    <MessageProvider>
      <div className="flex flex-col h-screen relative">
        <main className="flex-1 overflow-y-auto">
          {/* Book Collection visible when chat is inactive */}
          {!isChatActive && (
            <BookCollection
              title="My Collection"
              userLevel={userLevel}
              userEnergy={userEnergy}
              books={bookinfo} // Pass synchronized book data
            />
          )}

          {/* Chat Output */}
          <ChatOutput
            isChatActive={isChatActive}
            onClick={toggleChatRef.current}
          />

          {/* Chat Input */}
          <ChatInput onChatActivity={activateChat} />
        </main>
      </div>
    </MessageProvider>
  );
};

export default MainScreen;
