import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item !== null ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  });

  const debouncedSetItem = debounce((value) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting localStorage key "${key}":`, error);
    }
  }, 500); // Adjust the delay as needed

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      debouncedSetItem(valueToStore);
    } catch (error) {
      console.error(`Error updating localStorage key "${key}":`, error);
    }
  };

  useEffect(() => {
    return () => {
      debouncedSetItem.cancel();
    };
  }, []); // Ensure cleanup only runs on unmount

  return [storedValue, setValue];
};

export default useLocalStorage;
